import Sdk from "casdoor-js-sdk";
import { useAuthStore } from "../../store";

interface ISetTokenPrams {
  access_token: string;
  refresh_token: string;
  expiry: string;
}

export const ServerUrl = process.env.REACT_APP_CASDOOR_TOKEN_SERVER_URL;

const sdkConfig = {
  serverUrl: process.env.REACT_APP_CASDOOR_SERVER_URL,
  clientId: process.env.REACT_APP_CASDOOR_CLIENT_ID,
  appName: process.env.REACT_APP_CASDOOR_APP_NAME,
  organizationName: process.env.REACT_APP_CASDOOR_ORG_NAME,
  redirectPath: "/callback"
};

export const CasdoorSDK = new Sdk(sdkConfig);

export const SetToken = (data: ISetTokenPrams) => {
  const setAuthToken = useAuthStore.getState().setAuthToken;

  setAuthToken({
    token: data.access_token,
    refreshToken: data.refresh_token,
    expiry: data.expiry
  });
};

export const goToLink = (link: string) => {
  window.location.href = link;
};

export const getCasdoorSDKForRedictPath = (redirectPath: string) => {
  const CasdoorSDK = new Sdk({ ...sdkConfig, redirectPath });

  return CasdoorSDK;
};
