import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../store";
import useFleetAndDevicesStore from "../store/fleet-and-devices/fleet-and-devices.store";
import { IFleet, IProject } from "../interfaces";
import { useGetProjects } from "./shared/hooks/get";
import { useGetOrganizations } from "./shared/hooks/get/organizations";
import ShowLoading from "./shared/components/loading.component";

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const { org_id, proj_id } = params;

  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);

  const [
    setFleets,
    setSelectedFleet,
    setDevices,
    setProjects,
    setSelectedProject
  ] = useFleetAndDevicesStore((state) => [
    state.setFleets,
    state.setSelectedFleet,
    state.setDevices,
    state.setProjects,
    state.setSelectedProject
  ]);

  const { isLoading: orgsLoading } = useGetOrganizations((organizations) => {
    const data = { organization: organizations };
    if (organizations?.length && org_id) {
      const selectedOrg = organizations.find((org) => org.id === org_id);
      if (selectedOrg) {
        data["selectedOrg"] = selectedOrg;
      }
      console.log({ selectedOrg });
    }

    updateAuthUser(data);
  });

  const { isLoading: projectsLoading } = useGetProjects({}, (projects) => {
    if (projects?.length) {
      setProjects(projects);

      if (proj_id) {
        const proj = projects.find((proj) => proj.id === proj_id);
        setSelectedProject(proj ?? projects[0]);
      }
    } else {
      setProjects([]);
      setFleets([]);
      setDevices([]);

      setSelectedProject({} as IProject);
      setSelectedFleet({} as IFleet);

      // if not on project creation page, redirect to projects page
      if (
        location.pathname !== "/fleet-and-devices/projects/project-details"
      ) {
        navigate("/fleet-and-devices/projects");
      }
    }
  });

  return (
    <div className="bg-background-layer1 min-h-screen flex flex-col pb-8 font-dm-sans">
      {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={toggleSidebar} /> */}
      <div className="flex flex-col flex-1 h-full overflow-hidden bg-background-layer1 text-contentColor">
        {/* <CommonHeader /> */}
        {orgsLoading || projectsLoading ? <ShowLoading /> : <Outlet />}
      </div>
    </div>
  );
};

export default Layout;
