import { useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthStore } from "../../store";
import { useConsumeInvite } from "@app/shared/hooks/post/consume-invite";
import { toast } from "react-toastify";
import { getOrganizations } from "@app/shared/hooks/get/organizations";

const Invite = () => {
  const mounted = useRef(false);
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);

  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams.get("token");
  const nextPath = searchParams.get("nextPath");

  const consumeInviteMutation = useConsumeInvite(async (data) => {
    const orgs = await getOrganizations();

    updateAuthUser({
      selectedOrg: orgs.find((o) => o.id === data.orgId)
    });
    if (nextPath) {
      navigate(decodeURI(nextPath));
    } else {
      navigate("/home");
    }
    setSearchParams({ token: "" });
    toast.success("Successfully joined organization: " + data.orgName);
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (mounted.current) {
      return;
    }
    if (inviteToken) {
      consumeInviteMutation.mutate(inviteToken, {
        onError: () => {
          navigate("/home");
        }
      });
    } else {
      navigate("/");
    }

    return () => {
      mounted.current = true;
    };
  }, [
    consumeInviteMutation,
    inviteToken,
    navigate,
    setSearchParams,
    updateAuthUser
  ]);

  return consumeInviteMutation.status === "idle" ||
    consumeInviteMutation.isLoading ? (
    <>Please Wait, Redirecting...</>
  ) : (
    <div className="p-10">
      Could not consume the token.{" "}
      <Link to="/home" className="px-4 py-2 rounded-md bg-primary text-white">
        Go to home
      </Link>
    </div>
  );
};

export default Invite;
