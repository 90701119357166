import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import {
  IDashboard,
  ILineDefination,
  IPieDefinations,
  IGaugeDefination,
  IBarDefination,
  IOption,
  IShadowProtoStruct
} from "../../interfaces";
import dateService from "@services/date.service";
import { TBlueprintKind } from "@/interfaces/blueprint.interface";

export type TDashPanel =
  | ILineDefination
  | IPieDefinations
  | IGaugeDefination
  | IBarDefination;

export interface IFilterProps {
  type: string;
  span?: string;
  rangeStart?: Date;
  rangeEnd?: Date;
  start: string;
  stop: string;
}
export interface ICreatePanelState {
  panelName?: string;
  selectedPanel?: string;

  inputValues?: {
    dataPoints?: IOption[];
    shadow?: IShadowProtoStruct;
    aggregateParams?: boolean;
    devicesInDataPoints?: any[];
    selectedParams?: (IOption & { data: any })[];
    startDate?: string;
    endDate?: string;
    timePeriod?: {
      start: string;
      end: string;
    };
    timeBucket?: number;
    timeBucketUnit?: "mins" | "hours" | "days";
    filters?: {
      devices: any[];
      tags: any[];
    };
  };
}

export const initialVals: ICreatePanelState["inputValues"] = {
  dataPoints: [],
  aggregateParams: true,
  devicesInDataPoints: [],
  selectedParams: [],
  filters: {
    devices: [],
    tags: []
  },
  timePeriod: {
    start: "-10d",
    end: "0d"
  },
  timeBucket: 1,
  timeBucketUnit: "days" as "mins" | "hours" | "days"
};

interface IDashboardState {
  dashboards: IDashboard[];
  panels: TDashPanel[];
  carousels: {
    [x: string]: (
      | ILineDefination
      | IPieDefinations
      | IGaugeDefination
      | IBarDefination
    )[];
  };
  activeDashboard: IDashboard;
  activeFilter: IFilterProps;
  createPanelState: ICreatePanelState;
  zoomLevel: number;
  editingLayout: boolean;
  // all the layouts for all breakpoints ({lg: [...], md: [...], ...}})
  layouts: any;
  // panel specific additional stuff
  createPanelAppearance: any;

  // panel to create blueprint from
  blueprintPanel: TDashPanel;
  setBlueprintPanel: (x: TDashPanel) => void;

  createBlueprintModalOpen: boolean;
  setCreateBlueprintModalOpen: (x: boolean) => void;

  createBlueprintKind: TBlueprintKind;
  setCreateBlueprintKind: (x: TBlueprintKind) => void;

  expandedItemId: string;
  setExpandedItemId: (x: string) => void;

  setCreatePanelAppearance: (x: any | ((x: any) => any)) => void;
  setEditingLayout: (x: boolean) => void;
  setLayouts: (x: any) => void;
  setPanels: (
    x: (
      | ILineDefination
      | IPieDefinations
      | IGaugeDefination
      | IBarDefination
    )[]
  ) => void;
  setCarousels: (x: {
    [x: string]: (
      | ILineDefination
      | IPieDefinations
      | IGaugeDefination
      | IBarDefination
    )[];
  }) => void;
  setDashboards: (x: IDashboard[]) => void;
  setActiveDashboard: (x: IDashboard) => void;
  setActiveFilter: (x: IFilterProps) => void;
  setCreatePanelState: (
    x: ICreatePanelState | ((x: ICreatePanelState) => ICreatePanelState)
  ) => void;
  setInputValues: (
    x:
      | ICreatePanelState["inputValues"]
      | ((
          x: ICreatePanelState["inputValues"]
        ) => ICreatePanelState["inputValues"])
  ) => void;
  setPanelName: (x: ICreatePanelState["panelName"]) => void;
  clearCreatePanelState: () => void;
  setZoomLevel: (x: number) => void;
}

const useDashboardStore = create<IDashboardState>()(
  persist(
    devtools(
      (set) => ({
        dashboards: [],
        panels: [],
        zoomLevel: 100,
        editingLayout: false,
        carousels: {},
        activeDashboard: {} as IDashboard,
        activeFilter: {
          type: "time",
          span: "1h",
          start: dateService.getCurrentUTCDate().subtract(1, "hours").format(),
          stop: null
        } as IFilterProps,
        createPanelState: {
          inputValues: initialVals
        },
        selectedParams: [],
        layouts: {},
        createPanelAppearance: {},
        expandedItemId: null,
        setExpandedItemId: (id: string) =>
          set(
            produce((state) => {
              state.expandedItemId = id;
            }),
            false,
            "dashboard/setExpandedItemId"
          ),

        createBlueprintKind: null,
        setCreateBlueprintKind: (blueprintKind: TBlueprintKind) =>
          set(
            produce((state) => {
              state.createBlueprintKind = blueprintKind;
            }),
            false,
            "dashboard/setCreateBlueprintKind"
          ),

        blueprintPanel: null,
        setBlueprintPanel: (blueprintPanel) =>
          set(
            produce((state) => {
              state.blueprintPanel = blueprintPanel;
            }),
            false,
            "dashboard/setBlueprintPanel"
          ),

        createBlueprintModalOpen: false,
        setCreateBlueprintModalOpen: (createBlueprintModalOpen: boolean) =>
          set(
            produce((state) => {
              state.createBlueprintModalOpen = createBlueprintModalOpen;
            }),
            false,
            "dashboard/setCreateBlueprintModalOpen"
          ),

        setLayouts: (layout) =>
          set(
            produce((state) => {
              state.layouts = layout;
            }),
            false,
            "dashboard/setLayouts"
          ),
        setPanels: (panelData) =>
          set(
            produce((state) => {
              state.panels = panelData;
            }),
            false,
            "dashboard/setPanels"
          ),
        setCarousels: (carouselData) =>
          set(
            produce((state) => {
              state.carousels = carouselData;
            }),
            false,
            "dashboard/setCarousels"
          ),
        setDashboards: (dashboardItems) =>
          set(
            produce((state) => {
              state.dashboards = dashboardItems;
            }),
            false,
            "dashboard/setDashboards"
          ),
        setActiveDashboard: (dashboard) => {
          set(
            produce((state) => {
              state.activeDashboard = dashboard;
            }),
            false,
            "dashboard/setActiveDashboard"
          );
        },
        setActiveFilter: (filter) =>
          set(
            produce((state) => {
              state.activeFilter = filter;
            }),
            false,
            "dashboard/setActiveFilter"
          ),
        setCreatePanelState: (panelStateOrFunc) =>
          set(
            produce((state) => {
              state.createPanelState =
                typeof panelStateOrFunc === "function"
                  ? panelStateOrFunc(state.createPanelState)
                  : panelStateOrFunc;
            }),
            false,
            "dashboard/setCreatePanelState"
          ),
        setInputValues: (
          inputValuesOrFunc:
            | ICreatePanelState["inputValues"]
            | ((
                inputValues: ICreatePanelState["inputValues"]
              ) => ICreatePanelState["inputValues"])
        ) =>
          set(
            produce((state: IDashboardState) => {
              if (typeof inputValuesOrFunc === "function") {
                state.createPanelState.inputValues = inputValuesOrFunc(
                  state.createPanelState.inputValues
                );
              } else {
                state.createPanelState.inputValues = inputValuesOrFunc;
              }
            }),
            false,
            "dashboard/setInputValues"
          ),
        setPanelName: (name: string) =>
          set(
            produce((state: IDashboardState) => {
              state.createPanelState.panelName = name;
            }),
            false,
            "dashboard/setPanelName"
          ),
        setZoomLevel: (zoom) =>
          set(
            produce((state) => {
              state.zoomLevel = zoom;
            }),
            false,
            "dashboard/setZoomLevel"
          ),
        setEditingLayout: (editing) =>
          set(
            produce((state) => {
              state.editingLayout = editing;
            }),
            false,
            "dashboard/setEditingLayout"
          ),
        setCreatePanelAppearance: (defOrFunc) =>
          set(
            produce((state) => {
              state.createPanelAppearance =
                typeof defOrFunc === "function"
                  ? defOrFunc(state.createPanelAppearance)
                  : defOrFunc;
            }),
            false,
            "dashboard/setEditingLayout"
          ),

        clearCreatePanelState: () =>
          set(
            produce((state) => {
              state.createPanelState = {
                inputValues: initialVals
              } as ICreatePanelState;
              state.createPanelAppearance = {};
            }),
            false,
            "dashboard/clearCreatePanelState"
          )
      }),

      { name: "fleet-and-devices", serialize: { options: true } }
    ),
    {
      name: "dashboard", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useDashboardStore;
