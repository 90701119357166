import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useFleetAndDevicesStore, useDashboardStore } from "../../store";
import { IDashboard } from "../../interfaces";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowError from "../shared/components/error.component";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { useGetDashboards } from "@app/shared/hooks/get/dashboards";
import ShowLoading from "@app/shared/components/loading.component";

const AllDashboards: React.FC = () => {
  const navigate = useNavigate();

  const params = useParams();
  const dashId = params["dash_id"];

  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [
    dashboards,
    activeDashboard,
    setLayouts,
    setActiveDashboard,
    setDashboards,
    setPanels,
    setCarousels
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.activeDashboard,
    state.setLayouts,
    state.setActiveDashboard,
    state.setDashboards,
    state.setPanels,
    state.setCarousels
  ]);

  const { isLoading, error } = useGetDashboards({}, (dashboards) => {
    if (dashboards?.length) {
      setDashboards(dashboards);

      const dashboard = dashboards.find((d) => d.id === dashId);

      if (dashboard) {
        if (dashboard.meta_data?.layouts) {
          setLayouts(dashboard.meta_data.layouts);
        }
        setActiveDashboard(dashboard);
      } else {
        setActiveDashboard(dashboards[0]);
        if (dashboards[0].meta_data?.layouts) {
          setLayouts(dashboards[0].meta_data.layouts);
        }
        navigate(`./${dashboards[0].id}`, { replace: true });
      }
    } else {
      setPanels([]);
      setCarousels({});
      setDashboards([]);
      setActiveDashboard({} as IDashboard);
      navigate("/dashboard/", { replace: true });
    }
  });

  const handleTabChange = (dashboard: IDashboard) => {
    if (dashboard.id === activeDashboard.id) {
      return;
    }

    setPanels([]);

    if (dashboard.meta_data?.layouts) {
      setLayouts(dashboard.meta_data.layouts);
    } else {
      setLayouts({});
    }

    setActiveDashboard(dashboard);
    navigate(`./${dashboard.id}`);
  };

  const renderTabHeader = (tab: IDashboard, renderArrow: boolean) => {
    return (
      <div
        className={`relative flex flex-row items-center !w-full text-sm font-medium ${
          tab.id === activeDashboard.id
            ? "text-primaryLight"
            : "text-contentColor"
        }`}
      >
        <h4>{tab.dashboard_name}</h4>
        {renderArrow && tab.id === activeDashboard.id && (
          <ChevronDownIcon className="w-4 h-4 ml-1 mt-0 text-primary" />
        )}
      </div>
    );
  };

  const renderTabList = (width) => {
    return dashboards.map((dashboard, index) => (
      <div
        key={index}
        className={`relative mr-4 px-2 pb-1 mt-2 ${width} cursor-pointer border-b-2  ${
          activeDashboard.id === dashboard.id
            ? "border-primaryLight"
            : "border-transparent"
        } `}
        onClick={() => handleTabChange(dashboard)}
      >
        {renderTabHeader(dashboard, false)}
      </div>
    ));
  };

  if (error) {
    return <ShowError />;
  }

  if (isLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      <div className="bg-background border-b lg:px-8 sm:px-6 border-b-background-layer2">
        <div className="relative flex flex-col justify-bet">
          <div className="flex flex-col justify-between items-start pt-4 w-full">
            <h4 className="text-xl font-semibold text-contentColor">
              {selectedProject.project_name}
            </h4>
          </div>
          <div className="relative bg-background flex flex-row justify-start items-center mt-1 w-full">
            {error ? (
              <ShowError />
            ) : dashboards.length > 6 ? (
              <React.Fragment>
                <div className="relative w-10/12 mb-2 justify-start">
                  <TransitionedMenu
                    buttonComponent={
                      <div className="pb-1 mt-2 -mb-2 cursor-pointer border-b-2  border-primary">
                        {renderTabHeader(activeDashboard, true)}
                      </div>
                    }
                  >
                    {dashboards.map((dashboard) => (
                      <Menu.Item key={dashboard.id}>
                        <div
                          key={dashboard.id}
                          className={`relative pl-3 text-sm py-2 pr-10 cursor-pointer hover:bg-gray-100 ${
                            activeDashboard.id === dashboard.id
                              ? "bg-gray-100"
                              : ""
                          } `}
                          onClick={() => handleTabChange(dashboard)}
                        >
                          {dashboard.dashboard_name}
                        </div>
                      </Menu.Item>
                    ))}
                  </TransitionedMenu>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="flex flex-row justify-start items-center">
                  {renderTabList("w-30")}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      {/* Outlet for all routes!*/}
      <Outlet />
    </>
  );
};

export default AllDashboards;
