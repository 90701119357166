import { AuthCallback } from "casdoor-react-sdk";
import * as Setting from "./casdoor-setting.helper";
import { useSearchParams } from "react-router-dom";

export const Auth = () => {
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("token");

  const nextPath = searchParams.get("nextPath");

  return (
    <AuthCallback
      sdk={Setting.CasdoorSDK}
      serverUrl={Setting.ServerUrl}
      saveTokenFromResponse={(res: any) => {
        Setting.SetToken(res.data);
        if (inviteToken) {
          Setting.goToLink(
            `/invite?token=${inviteToken}&nextPath=${nextPath}`
          );
        } else {
          Setting.goToLink(decodeURI(nextPath ?? "") || "/");
        }
      }}
      isGetTokenSuccessful={(res: any) => {
        return res?.ok === 1;
      }}
    />
  );
};
